import axios from 'axios';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as HidePWSVG } from '../../../images/password-reveal-hide.svg';
import { ReactComponent as ShowPwSVG } from '../../../images/password-reveal-show.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { isBrowser } from '../../../utils/browser';
import { LocaleType } from '../../../utils/i18n';
import { EMAIL_VALIDATION } from '../../../utils/regexes';
import {
  redirectToDashboardAccount,
  redirectToDashboardBusinessEdit,
  redirectToDashboardOpportunities,
  setUserSelectionData,
} from '../../../utils/user';
import './login.scss';
import Loading from '../../global/loading/loading';

type FormItem = {
  value: string;
  isValid: boolean;
  isTouched?: boolean;
};

const Login: React.FC = () => {
  const { i18n, invitationToken, invitation } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [email, setEmail] = useState<FormItem>({
    value: invitation ? invitation.email : '',
    isValid: invitation ? true : false,
    isTouched: false,
  });
  const [password, setPassword] = useState<FormItem>({
    value: '',
    isValid: false,
    isTouched: false,
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetAllError = () => {
    setError(false);
    setLoginError(false);
  };

  const handleChange = (
    value: string,
    setFn: Dispatch<SetStateAction<FormItem>>,
    field: string
  ) => {
    let isValid = false;
    if (field === 'password') isValid = value.length > 0 ? true : false;
    if (field === 'email') isValid = EMAIL_VALIDATION.test(value);
    setFn({ value, isValid });
  };

  const handleBlur = (setFn: Dispatch<SetStateAction<FormItem>>) => {
    setFn((prevValue: FormItem) => {
      return { ...prevValue, isTouched: true };
    });
  };

  const checkValidityOnSubmit = (): boolean => {
    return email.isValid && password.isValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .post(`${process.env.GATSBY_STRAPI_URL}/api/auth/local`, {
            identifier: email.value,
            password: password.value,
            locale: currentLocale,
            invitationToken,
          })
          .then((response) => {
            if (response.status === 200) {
              resetAllError();

              const { jwt, redirect, userSelection } = response.data;
              if (isBrowser) window.localStorage.setItem('jwt', jwt);

              setUserSelectionData(userSelection);

              if (redirect === 'account')
                redirectToDashboardAccount(currentLocale, i18n);
              else if (redirect === 'opportunities')
                redirectToDashboardOpportunities(currentLocale, i18n);
              else redirectToDashboardBusinessEdit(currentLocale, userSelection.id, i18n);
            }
          })
          .catch((err) => {
            resetAllError();
            setLoginError(true);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setLoginError(true);
        setSubmitting(false);
      }
    }
  };

  let registerSlug = i18n?.t('register.slug');
  registerSlug = registerSlug ? registerSlug : 'register';

  let forgotPasswordSlug = i18n?.t('forgot.password.slug');
  forgotPasswordSlug = forgotPasswordSlug
    ? forgotPasswordSlug
    : 'forgot-password';

  return (
    <div className="auth-content-wrapper">
      <div className="auth-form-wrapper login-wrapper">
        <h2 className="auth-title">{i18n?.t('login.title')}</h2>
        <span className="auth-subtitle">
          {i18n?.t('dont_have_an_account')}
        </span>{' '}
        <a
          className="auth-link"
          href={
            `/${currentLocale}/${registerSlug}` +
            (invitationToken ? `?i=${invitationToken}` : '')
          }
        >
          {i18n?.t('create_your_account')}
        </a>
        <form className="login-account-form" onSubmit={(e) => handleSubmit(e)}>
          <div>
            <label>{i18n?.t('form.labels.email2')}</label>
            <input
              type="email"
              onChange={(e) => {
                handleChange(e.target.value, setEmail, 'email');
              }}
              onBlur={() => {
                handleBlur(setEmail);
              }}
              className={!email.isValid && email.isTouched ? 'red-border' : ''}
              defaultValue={email.value}
              disabled={invitation !== undefined}
            ></input>
            {!email.isValid && email.isTouched && (
              <span className="warning">
                {i18n?.t('form.errors.invalid_email2')}
              </span>
            )}
          </div>
          <div>
            <label>{i18n?.t('form.labels.password')}</label>
            <div className="password-row">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                onChange={(e) => {
                  handleChange(e.target.value, setPassword, 'password');
                }}
                onBlur={() => {
                  handleBlur(setPassword);
                }}
                className={
                  !password.isValid && password.isTouched ? 'red-border' : ''
                }
              ></input>
              {isPasswordVisible ? (
                <HidePWSVG
                  className="hide"
                  onClick={() => {
                    setIsPasswordVisible((prevValue) => !prevValue);
                  }}
                />
              ) : (
                <ShowPwSVG
                  className="show"
                  onClick={() => {
                    setIsPasswordVisible((prevValue) => !prevValue);
                  }}
                />
              )}
            </div>
            {!password.isValid && password.isTouched && (
              <span className="warning">
                {i18n?.t('form.errors.required_your_password')}
                <br />
              </span>
            )}
            <a
              className="auth-forgot-password-link"
              href={
                `/${currentLocale}/${forgotPasswordSlug}` +
                (invitationToken ? `?i=${invitationToken}` : '')
              }
              style={{ marginTop: '5px' }}
            >
              {i18n?.t('forgot_your_password')}
            </a>
          </div>

          <button className="submit-button" type="submit">
            {submitting && <Loading type="btn" />}
            {!submitting && i18n?.t('login')}
          </button>
        </form>
        {error && (
          <div className="error-container">
            <div className="icon-container">
              <WarningSVG />
            </div>
            <p>{i18n?.t('form.errors.all_fields')}</p>
          </div>
        )}
        {loginError && (
          <div className="error-container">
            <div className="icon-container">
              <WarningSVG />
            </div>
            <p>
              {i18n?.t('form.errors.wrong_credentials1')}{' '}
              <a href={`/${currentLocale}/${forgotPasswordSlug}`}>
                {i18n?.t('form.errors.wrong_credentials2')}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
